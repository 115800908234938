define("bottomline-execution/controllers/trips/index", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/service", "rsvp", "@ember/utils", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/ask-odometer", "bottomline-execution/mixins/storages-loader", "bottomline-execution/mixins/trip-sorter", "bottomline-execution/mixins/map-element-data"], function (_exports, _computed, _controller, _object, _service, _rsvp, _utils, _appState, _askOdometer, _storagesLoader, _tripSorter, _mapElementData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, _askOdometer.default, _mapElementData.default, _storagesLoader.default, _tripSorter.default, {
    i18n: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    store: (0, _service.inject)(),
    selectedTrip: (0, _computed.alias)('appState.selectedTrip'),
    selectedTripId: (0, _computed.alias)('selectedTrip.id'),
    selectedTripStop: (0, _computed.alias)('appState.selectedTripStop'),
    selectedTripStopId: (0, _computed.alias)('selectedTripStop.id'),
    smallScreenSize: (0, _computed.alias)('truckSettings.small_screen_ui'),
    normalScreenSize: (0, _computed.not)('smallScreenSize'),
    invertedWidth: false,
    isVisible: true,
    defaultMode: true,
    isOpeningTicket: false,
    isOpeningReport: false,
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    online: (0, _computed.alias)('appState.online'),
    offline: (0, _computed.not)('online'),
    currentTruck: (0, _computed.alias)('appState.currentTruck'),
    askForOdometer: (0, _computed.alias)('truckSettings.odometer_on_trip_level'),
    useMaterialCalculation: (0, _computed.alias)('truckSettings.use_material_calculation'),
    showAtgInformation: (0, _computed.alias)('truckSettings.show_atg_information'),
    deprecatedSourceForTripReport: (0, _object.computed)(function () {
      return this.get('truckSettings.deprecated_source_trip_report');
    }),
    deprecatedSourceForDriverReport: (0, _object.computed)(function () {
      return this.get('truckSettings.deprecated_source_driver_report');
    }),
    trips: (0, _computed.alias)('model'),
    startedTrip: (0, _object.computed)('model.@each.state', function () {
      return this.get('model').toArray().find(function (trip) {
        return trip.get('state') === 'started';
      });
    }),
    tripIsStarted: (0, _object.computed)('startedTrip', function () {
      return (0, _utils.isPresent)(this.get('startedTrip'));
    }),
    selectedTripStopTripIsStarted: (0, _object.computed)('selectedTripStop.trip.state', 'startedTrip', function () {
      if ((0, _utils.isBlank)(this.get('selectedTripStop'))) return false;
      return this.get('startedTrip') && this.get('selectedTripStop.trip.state') === 'started';
    }),
    showActions: (0, _object.computed)(function () {
      var _this = this;

      var actions = this.get('truckSettings.settingNames').filter(function (name) {
        return name.startsWith('trips_panel_actions');
      });
      return actions.any(function (action) {
        return _this.get('truckSettings').get(action);
      });
    }),
    showATGInformation: (0, _object.computed)('selectedTripStop', function () {
      return this.get('truckSettings.trips_panel_atg_information') && this.get('selectedTripStop.site.isDeliveryLocation');
    }),
    showATGWarnings: (0, _object.computed)('selectedTripStop', function () {
      return this.get('truckSettings.trips_panel_atg_warnings') && this.get('selectedTripStop.isLoad') && (0, _utils.isPresent)(this.get('tanksWithATGProblems'));
    }),
    tanksWithATGProblems: (0, _computed.alias)('selectedTripStop.trip.tanksWithATGProblems'),
    sortedTripStopStorages: (0, _object.computed)('selectedTripStop.trip_stop_storages.@each', function () {
      return this.get('selectedTripStop.trip_stop_storages').sortBy('storage.SequenceNb');
    }),
    remarksForDriverPresent: (0, _object.computed)('selectedTripStop.hasRemarks', function () {
      return (0, _utils.isPresent)(this.get('selectedTripStop')) && this.get('selectedTripStop.hasRemarks');
    }),
    shift: (0, _object.computed)('model.firstObject', function () {
      return this.get('model.firstObject.shift');
    }),
    shiftStateStarted: (0, _object.computed)('model.firstObject.shift.state', function () {
      return this.get('model.firstObject.shift.state') === 'started';
    }),
    canChangeTerminal: (0, _object.computed)('startedTrip.hasLoadStop', 'startedTrip.loadStopIsConfirmed', function () {
      return this.get('startedTrip.hasLoadStop') && !this.get('startedTrip.loadStopIsConfirmed');
    }),
    appController: (0, _controller.inject)('application'),
    siteStoragesLoader: (0, _computed.alias)('appController.siteStoragesLoader'),
    tripStopsOnTheMap: (0, _object.computed)('selectedTrip.displayableTripStops.@each.isConfirmed', 'selectedTripStop', function () {
      var tripStops = this.get('selectedTrip.displayableTripStops');
      if ((0, _utils.isEmpty)(tripStops)) return [];
      return this.getTripStopsOnTheMap(tripStops, this.get('selectedTripStop'));
    }),
    iconSetForInitialZoomGeoMap: (0, _object.computed)('tripStopsOnTheMap.@each.isConfirmed', function () {
      return this.getIconSetForInitialZoomMapDefault();
    }),
    includeCurrentLocationInZoomGeoMap: false,
    actions: {
      toggleShowTrip: function toggleShowTrip(trip) {
        trip.toggleProperty('isShown');
      },
      toggleInvertedWidth: function toggleInvertedWidth(val) {
        this.set('invertedWidth', val);
      },
      updateState: function updateState(action, model) {
        var _this2 = this;

        this.get('appState').setSelectedTripId(model.id);

        switch (action.state) {
          case 'started':
            // Start and Restart
            this._askForOdometer(model, this.get('i18n').t('start_of_trip'), 'OdometerStart').then(function () {
              _this2._retrieveStaleSiteStorages(model);

              switch (action.action) {
                case 'start':
                  model.start();
                  break;

                case 'restart':
                  model.restart();
                  break;
              }

              model.get('shift.trips').forEach(function (trip) {
                return trip.notifyPropertyChange('state');
              });
            });

            break;

          case 'finished':
            // Finish
            this._askForOdometer(model, this.get('i18n').t('end_of_trip'), 'OdometerEnd').then(function () {
              model.finish();
            });

            break;

          case 'planned':
            // Cancel
            model.cancel();
            break;
        }
      },
      viewTicket: function viewTicket(trip) {
        var _this3 = this;

        var pdfDocument = this.get('store').createRecord('pdf-document', {
          documentType: 'tripReport',
          modelId: trip.get('id')
        });

        var flagTripReportOpening = function flagTripReportOpening(opening) {
          _this3.set('isOpeningTicket', opening);
        };

        var errorOpeningDocument = function errorOpeningDocument(error) {
          _this3._errorOpeningDocument(error);
        };

        pdfDocument.open({
          signalOpening: flagTripReportOpening,
          showWarning: errorOpeningDocument
        });
      },
      viewReport: function viewReport(trip) {
        var _this4 = this;

        var flagDriverReportOpening = function flagDriverReportOpening(opening) {
          _this4.set('isOpeningReport', opening);
        };

        var errorOpeningDocument = function errorOpeningDocument(error) {
          _this4._errorOpeningDocument(error);
        };

        this.get('store').query('pdf-document', {
          documentType: 'driverReport',
          modelId: trip.get('id')
        }).then(function (documentsList) {
          // Drop documents with description 'ticket', there is a separate button for that document type
          documentsList = documentsList.rejectBy('description', 'ticket');
          var documentOpenOptions = {
            signalOpening: flagDriverReportOpening,
            showWarning: errorOpeningDocument
          };

          switch (documentsList.length) {
            case 0:
              _this4.get('toast').info(_this4.get('i18n').t('trip.documents.notfound'));

              break;

            case 1:
              documentsList.get('firstObject').open(documentOpenOptions);
              break;

            default:
              _this4._showDocumentSelector(documentsList, documentOpenOptions);

          }
        }).catch(function (error) {
          _this4._errorOpeningDocument(error);
        });
      },
      viewRemarks: function viewRemarks(trip) {
        this.get('appState').setSelectedTripId(trip.get('id'));
        this.set('defaultMode', false);
      },
      closeRemarks: function closeRemarks() {
        this.set('defaultMode', true);
      },
      skipTrip: function skipTrip(trip) {
        trip.finish();
      },
      syncTrip: function syncTrip(trip) {
        var _this5 = this;

        var tripId = trip && trip.id;

        if (!tripId || !trip.get('isShown')) {
          return;
        }

        trip.set('beingProcessed', true);
        trip.reload().then(function (trip) {
          trip.calculateEndInventoryAndCorrection();
          return _this5._retrieveUnloadSiteStorages(trip);
        }).finally(function () {
          trip.set('beingProcessed', false);
        });
      },
      setSelectedTripStopId: function setSelectedTripStopId(tripId) {
        this.get('appState').setSelectedTripStopId(tripId);
      }
    },
    _retrieveUnloadSiteStorages: function _retrieveUnloadSiteStorages(trip) {
      if (this.get('online')) {
        var siteIds = trip.deliveryStops().mapBy('site.id').uniq();
        return this.loadSiteStorages(siteIds);
      } else {
        return _rsvp.Promise.resolve();
      }
    },
    _retrieveStaleSiteStorages: function _retrieveStaleSiteStorages(trip) {
      var siteIds = trip.sitesWithStaleStorages().mapBy('SiteID');

      if ((0, _utils.isPresent)(siteIds)) {
        this.loadSiteStorages(siteIds);
      }
    },
    getAllTripStops: function getAllTripStops() {
      var _this6 = this;

      return this.get('store').peekAll('trip-stop').filter(function (tripStop) {
        return tripStop.get('tripID') === _this6.get('trip').id;
      });
    },
    _errorOpeningDocument: function _errorOpeningDocument(error) {
      this.get('toast').error(this.get('i18n').t('trip.documents.error') + error.message);
    },
    _showDocumentSelector: function _showDocumentSelector(documentsList, documentOpenOptions) {
      var _this7 = this;

      var openDocument = function openDocument(document) {
        try {
          document.open(documentOpenOptions);
        } catch (error) {
          _this7._errorOpeningDocument(error);
        }
      };

      var documentButtons = documentsList.map(function (document) {
        return {
          text: _this7.get('i18n').t("trip.documents.".concat(document.description), {
            default: document.description
          }),
          onClick: function onClick() {
            openDocument(document);
          }
        };
      });
      this.get('f7').modal({
        text: this.get('i18n').t('trip.documents.dialogText'),
        verticalButtons: true,
        buttons: documentButtons
      });
    }
  });

  _exports.default = _default;
});